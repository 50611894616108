<template>

  <div class="row" v-if="produit">
    <div class="col-lg-8">
       <div class="ibox">
        <div class="ibox-content">
          <h1>Profil</h1>
          
          <div class="form-group">
            <label for="">Prenom :</label>
            <input v-model="v$.user.prenom.$model"  class="form-control" :class="{ 'form-error': v$.user.prenom.$errors.length }"/>
          </div>
          <div class="form-group">
            <label for="">Nom :</label>
            <input v-model="v$.user.nom.$model"  class="form-control" :class="{ 'form-error': v$.user.nom.$errors.length }"/>
          </div>
          <div class="form-group">
            <label for="">Mot de passe :</label>
            <input v-model="v$.user.password.$model" class="form-control" :class="{ 'form-error': v$.user.password.$errors.length }" />
          </div>
          <div class="form-group">
            <label for="">Email :</label>
            <input v-model="v$.user.email.$model" class="form-control" :class="{ 'form-error': v$.user.email.$errors.length }"/>
          </div>
          <div>
            <button v-on:click="saveData()" class="btn btn-primary">Valider</button>
          </div>
        </div>
       </div>
    </div>
    <div class="col-md-4">
      <div class="ibox">
        <div class="ibox-content">
          <h2>Image</h2>
          <div v-if="user.image">
            <img :src="user.image.full_path" width="200" alt="">
          </div>
          <FileUploader :uploadurl="uploadImageUrl" @add-document="addDocument" />    
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { h } from 'vue'
import FileUploader from "@/components/FileUploader";
import { required, minLength } from '@vuelidate/validators'
import { mapGetters } from "vuex";
import useVuelidate from '@vuelidate/core'
import { USER_REQUEST, USER_UPDATE_PROFIL } from "../store/actions/user";

export default {
  name: "Profil",
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      produit: {},
      user: {},
    };
  },
  validations() {
      return {
        user: {
          nom: { 
              required,
              minLength: minLength(1)
          },
          prenom: { 
              required,
              minLength: minLength(1)
          },
          email: { 
              required,
              minLength: minLength(1)
          },
          password: {               
              minLength: minLength(1)
          },
        },
        
      }
  },
  watch: {
    // call again the method if the route changes
    // $route: "fetchData",
  },
  methods: {   
    saveData() {
      this.v$.$touch()
      if( !this.v$.$invalid){
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });           
        this.$store.dispatch(USER_UPDATE_PROFIL, this.user).then(() => {
              loading.close();              
              this.$notify({
                title: 'Profil mis à jour',
                message: h('i', { style: 'color: teal' }, '')
              });
          }).catch((error) => {
              loading.close();
              this.$notify({
                title: 'Erreur',
                message: h('i', { style: 'color: teal' }, '')
              });
              console.log("ERROR", error)
          });
      }
    },
    addDocument(doc){
      this.user.image = doc
      this.saveData()
    },
  },
  computed: {
    ...mapGetters(["getProfile", "isProfileLoaded"]),
    uploadImageUrl() {
      return process.env.VUE_APP_API + '/producteur/document/upload/image'
    },
  },
  components: {
    FileUploader,
  },
  async mounted() {
        await this.$store.dispatch(USER_REQUEST);
        this.user = { ...this.getProfile };        
        this.user.password = "";
        this.image = this.user.image ? this.user.image.full_path : "";
    },
};
</script>