<template>
    <div>
        <div v-if="loading">loading...</div>
        <file-upload
            class="btn btn-primary"
            :post-action="uploadurl"
            :extensions="extensions"
            :accept="accept"
            :multiple="true"
            :active="true"            
            :size="16 * 1024 * 1024"            
            v-on:input-filter="inputFilter"
            v-on:input-file="inputFile"
            :input-id="name"
            ref="upload">
                <i class="fa fa-plus"></i>
                Ajouter un document
            </file-upload>
    </div>
</template>

<script>
// import axios from 'axios';
import { h } from 'vue'

export default{
    name: 'file-uploader',
    emits: ['add-document'],
    props: {
        multiple: Boolean,
        form_field_id: String,
        uploadurl: String,
        name : {
            default: "file"
        },
        extensions : {
            default: "gif,jpg,jpeg,png,webp"
        },
        accept : {
            default: "image/png,image/gif,image/jpeg,image/webp"
        }
    },
    data: function(){
        return {   
            loading: false,       
        };
    },
    methods : {
        /**
         * Has changed
         * @param  Object|undefined   newFile   Read only
         * @param  Object|undefined   oldFile   Read only
         * @return undefined
         */
        inputFile(newFile, oldFile) {            
            // newFile.xhr.setRequestHeader('Authorization', 'Bearer zefzefze' );
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {                
                if (!this.$refs.upload.active) {                    
                    this.$refs.upload.active = true
                    this.loading = true
                }
                if(newFile.error){
                    this.$notify({
                        title: 'Erreur Upload',
                        message: h('i', { style: 'color: teal' }, '')
                    });
                }
            }
            if (newFile && oldFile && !newFile.active && oldFile.active) {
                if (newFile.xhr) {
                    if(newFile.response){
                        //check if response is OK
                        if(newFile.response.id){                            
                            this.$emit('add-document', newFile.response)
                        }
                        // this.offre.gallery.push(newFile.response)
                    }
                    //  Get the response status code          
                    this.loading = false
                }
            }
        },
        /**
         * Pretreatment
         * @param  Object|undefined   newFile   Read and write
         * @param  Object|undefined   oldFile   Read only
         * @param  Function           prevent   Prevent changing
         * @return undefined
         */
        inputFilter(newFile,/* oldFile, prevent*/) {      
            newFile.headers = {
                'Authorization' : 'Bearer ' + this.$store.state.auth.token
            }
            // if (newFile && !oldFile) {
            //     // Filter non-image file
            //     if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
            //     return prevent()
            //     }
            // }

            // Create a blob field
            newFile.blob = ''
            let URL = window.URL || window.webkitURL
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file)
            }
        },
    },
    mounted() {
        //if(!this.name) this.name = 'file-uploader'
    },
    
};
</script>

<style scoped>

</style>